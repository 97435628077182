import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import VCalendar from 'v-calendar';

Vue.config.productionTip = false
Vue.use(CoreuiVue)
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

store.dispatch('checkL').finally(() => {
  new Vue({
    router,
    store,
    icons,
    beforeCreate(){
      this.$store.commit('initializeAuth')
    },
    render: h => h(App)
  }).$mount('#app')
})

